<template>
  <v-form v-model="formValid">
    <v-layout wrap>
      <v-flex
        v-for="(field, index) in fields"
        :key="index"
        xs12
        sm4
        px-2
        pb-1
      >
        <div v-if="!field.hide">
          <component
            :is="field.type"
            v-if="field.type === 'interval' || field.key === 'duration'"
            v-model="doseDetails[field.key]"
            :append-text="field.unit"
            :label="field.label"
            :disabled="field.isReadonly"
            :required="field.isRequired"
            :field-key="field.key"
            :items="field.items"
            :validate="validate"
            :allow-user-to-edit="allowUserToEdit"
          />
          <component
            :is="field.type"
            v-else-if="field.type === 'dropdown'"
            v-model="patientDetails[field.key]"
            :append-text="field.unit"
            :label="field.label"
            :disabled="field.isReadonly"
            :required="field.isRequired"
            :field-key="field.key"
            :items="field.items"
            :validate="validate"
            :allow-user-to-edit="allowUserToEdit"
          />
          <component
            :is="field.type"
            v-else-if="field.type === 'radio'"
            v-model="patientDetails[field.key]"
            :append-text="field.unit"
            :label="field.label"
            :disabled="field.isReadonly"
            :field-key="field.key"
            :items="field.items"
          />
          <SubFields
            v-else-if="field.sub_fields"
            :patient-details="patientDetails"
            :sub-fields="field.sub_fields"
            :field="field"
          />

          <component
            :is="field.type"
            v-else
            v-model="patientDetails[field.key]"
            :append-text="field.unit"
            :label="field.label"
            :allow-user-to-edit="allowUserToEdit"
            :disabled="field.isReadonly || !allowUserToEdit"
            :required="field.isRequired"
            :field-key="field.key"
            :items="field.items"
            :min="field.min"
            :max="field.max"
            :max-date="today"
            :validate="validate"
            :message="field.message"
            :error-message="field.errorMessage"
            :common-function="commonFunction"
            @change="onChange($event, field)"
          />
        </div>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import Number from "@/components/form/NumberInput";
import Dropdown from "@/components/form/Dropdown";
import Date from "@/components/form/Datepicker";
import patientAgeMixin from "@/mixins/patientAgeMixin";
import Radio from "@/components/form/Radio";
import { orderBy } from "lodash";
import { DateTime } from "luxon";
import SubFields from "@/components/patient/SubFields";

export default {
  name: "PatientInformation",

  components: {
    Number,
    Date,
    SubFields,
    Radio,
    Dropdown,
  },

  mixins: [patientAgeMixin],

  props: {
    schema: { type: Array, required: true, default: () => [] },
    existingDoseDetails: { type: Object, required: true, default: () => ({}) },
    existingPatientDetails: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    allowUserToEdit: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      today: DateTime.local().toISODate(),
      formValid: false,
      patientDetails: this.existingPatientDetails,
      doseDetails: this.existingDoseDetails,
      fields: [],
      validate: false,
    };
  },
  watch: {
    existingPatientDetails(value) {
      this.patientDetails = value;
    },
    existingDoseDetails(value) {
      this.doseDetails = value;
    },
    patientDetails: {
      deep: true,
      handler(value) {
        this.$emit("update:existingPatientDetails", value);
      },
    },
    doseDetails: {
      deep: true,
      handler(value) {
        this.$emit("update:existingDoseDetails", value);
      },
    },
  },
  created() {
    this.fields = orderBy(this.schema, ["order"], ["asc"]);
    // this.updateField("gestationalAge", "hide", true);
  },

  methods: {
    async action(v, key) {
      const valid = v.fields.every(
        (field) =>
          this.patientDetails[field] != undefined &&
          this.patientDetails[field] != ""
      );
      if (valid) {
        this.commonFunction(v, key, "patientInformation");
      }
    },

    async onChange(value, field) {
      if (value) {
        field.validator.map((valid) => {
          if (valid.type == "dependent") {
            valid.fields.map((field) => {
              this.fields.map((f) => {
                if (f.key == field) {
                  f.validator.map((v) => {
                    if (v.type == "internal" || v.type == "external") {
                      this.commonFunction(v, field, "patientInformation");
                    }
                  });
                }
              });
            });
          } else {
            if (valid.type == "external") {
              this.commonFunction(valid, field, "patientInformation");
            }
          }
        });
      }
    },
    commonFunction(v, key, type) {
      this.$emit("commonFunction", v, key, type);
    },
    updateField(key, property, value) {
      const index = this.fields.findIndex((field) => field.key === key);
      if (index !== -1) {
        this.fields[index][property] = value;
        this.fields = this.fields.map((field, i) => {
          if (i === index) {
            return { ...field, [property]: value };
          }
          return field;
        });
      }
    },
  },
};
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
/* mozilla Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
