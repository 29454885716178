<template>
  <v-app
    id="login"
    class="secondary"
  >
    <v-content class="grad1">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-layout
              align-center
              justify-center
            >
              <img
                src="@/assets/pumas-brand-header.svg"
                alt="Vue Material Admin"
                width="180"
                height="180"
              >
            </v-layout>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row>
          <v-col
            sm="12"
            lg="6"
          >
            <v-layout
              align-center
              justify-center
            >
              <v-img
                lazy-src="@/assets/slider_3.svg"
                src="@/assets/slider_3.svg"
              />
            </v-layout>
          </v-col>
          <v-col
            sm="12"
            lg="5"
          >
            <v-layout
              align-center
              justify-center
              class="text-center"
            >
              <v-flex xs10>
                <div class="layout column align-center">
                  <h2>Lyv</h2>
                  <h4 class="flex my-4 theme--text">
                    Precision Healthcare
                  </h4>
                </div>
                <v-btn
                  class="theme--white"
                  color="#EF515B"
                  :loading="loading"
                  @click="login"
                >
                  Login
                </v-btn>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar
        v-model="ui.infoSnackbar.state"
        top
        :timeout="ui.infoSnackbar.timeout || 6000"
        :color="ui.infoSnackbar.color"
      >
        {{ ui.infoSnackbar.info }}
      </v-snackbar>
    </v-content>
    <Snackbar />
  </v-app>
</template>

<script>
import Snackbar from "../../components/ui/Snackbar";
import { Auth } from "aws-amplify";
import Error_Message from "@/utils/notification";
export default {
  name: "Login",
  components: {
    Snackbar,
  },
  data() {
    return {
      loading: false,
      error: false,
      showResult: false,
      result: "",
      ui: {
        retrieveLoading: false,
        infoSnackbar: {
          state: false,
          color: "error",
          info: null,
          timeout: 6000,
        },
      },
    };
  },
  mounted() {
    this.ui.retrieveLoading = true;
  },

  methods: {
    async login() {
      try {
        // Call Auth.federatedSignIn()
        await Auth.federatedSignIn();
        // If the authentication is successful, you can redirect or perform other actions
        // eslint-disable-next-line no-console
        console.log('Federated sign-in successful');
        // Redirect or perform other actions for authenticated users here
      } catch (e) {
        this.$store.commit(
          "snackbar/setSnack",
          Error_Message.display_message(e)
        );
        // Handle specific error cases or show error messages to the user
      }
    },
  },
};
</script>

<style scoped lang="scss">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}

.grad1 {
  background: linear-gradient(120deg, #c2c4c5, #c2c4c5, #ebb9a9 85%);
  background-size: 200% 200%;
  -webkit-animation: Animation 5s ease infinite;
  -moz-animation: Animation 5s ease infinite;
  animation: Animation 5s ease infinite;
}

@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }

  50% {
    background-position: 91% 100%;
  }

  100% {
    background-position: 10% 0%;
  }
}

@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }

  50% {
    background-position: 91% 100%;
  }

  100% {
    background-position: 10% 0%;
  }
}

@keyframes Animation {
  0% {
    background-position: 10% 0%;
  }

  50% {
    background-position: 91% 100%;
  }

  100% {
    background-position: 10% 0%;
  }
}

v-h1 {
  font-size: 5rem;
  line-height: 3.65rem;
  margin-bottom: 4rem;
  margin-top: 4rem;
}
</style>
