/* eslint-disable no-console */
/* eslint-disable no-undef */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "@/plugins/vuetify";
// import './plugins/vuetify'
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "./styles/global.scss";
import store from "./store/index";
import "babel-polyfill";
// import IdleVue from "idle-vue";
import dateUtils from "@/utils/index";
import config from "@/config/instance.json";
import VueDOMPurifyHTML from "vue-dompurify-html";
import { Amplify } from "aws-amplify";

Vue.use(VueDOMPurifyHTML);
/* eslint-disable no-undef */
// Vue.use(AmplifyVue, Amplify)
const {
  VUE_APP_AWS_COGNITO_REGION,
  VUE_APP_AWS_COGNITO_POOL_ID,
  VUE_APP_AWS_COGNITO_CLIENT_ID,
  VUE_APP_AWS_COGNITO_DOMAIN,
  VUE_APP_AWS_COGNITO_CALLBACK_URL,
} = process.env;

console.log(VUE_APP_AWS_COGNITO_REGION, "VUE_APP_AWS_COGNITO_REGION");
console.log(VUE_APP_AWS_COGNITO_POOL_ID, "VUE_APP_AWS_COGNITO_POOL_ID");
console.log(VUE_APP_AWS_COGNITO_CLIENT_ID, "VUE_APP_AWS_COGNITO_CLIENT_ID");
console.log(VUE_APP_AWS_COGNITO_DOMAIN, "VUE_APP_AWS_COGNITO_DOMAIN");
console.log(
  VUE_APP_AWS_COGNITO_CALLBACK_URL,
  "VUE_APP_AWS_COGNITO_CALLBACK_URL"
);

Amplify.configure({
  Auth: {
    region: VUE_APP_AWS_COGNITO_REGION,
    userPoolId: VUE_APP_AWS_COGNITO_POOL_ID,
    userPoolWebClientId: VUE_APP_AWS_COGNITO_CLIENT_ID,
    oauth: {
      domain: VUE_APP_AWS_COGNITO_DOMAIN,
      scope: ["email", "openid"],
      redirectSignIn: VUE_APP_AWS_COGNITO_CALLBACK_URL,
      redirectSignOut: VUE_APP_AWS_COGNITO_CALLBACK_URL,
      responseType: "code",
    },
  },
});

// const eventsEmitter = new Vue();

// Vue.use(IdleVue, {
//   eventsEmitter,
//   store,
//   idleTime: 5 * 60 * 1000,
//   startAtIdle: false,
// });

Vue.config.productionTip = false;

Vue.filter("formatDate", (value) => dateUtils.millisToFormattedDate(value));
Vue.filter("formatTime", (value) => dateUtils.millisToFormattedTime(value));
Vue.filter("formatDob", (value) => dateUtils.dobToFormatted(value));
Vue.use(vuetify);
Vue.prototype.$config = config;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
