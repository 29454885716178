<template>
  <div>
    <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
    >
      <v-card>
        <v-card-title id="message">
          {{ message }}
        </v-card-title>
        <v-card-actions class="pt-0">
          <v-spacer />
          <v-btn 
            id="agree" 
            @click.native="agree"
          >
            ok
          </v-btn>
          <v-btn 
            id="cancel"
            @click.native="cancel"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout 
      wrap 
      column
    >
      <v-flex text-right>
        <v-btn
          v-if="mutable"
          v-show="addEvent"
          color="success"
          :disabled="!allowUserToEdit"
          @click="addItem"
        >
          Add Event
        </v-btn>
        <EventEntry
          v-if="showEventEntry"
          :general-details="generalDetails"
          :edit-event="editEvent"
          :schema="schema"
          @close="onCloseEventEntry"
          @save="onSaveEventEntry"
        />
      </v-flex>
      <v-data-table
        :headers="headers"
        :items="events"
        single-select
        item-key="__ob__.dep.id"
        @click:row="rowClick"
      >
        <template #[`item.event`]="{ item }">
          <v-icon small>
            {{ item.type === "Level" ? "assessment" : "colorize" }}
          </v-icon>
          {{ item.event || item.type }}
        </template>
        <template #[`item.time`]="{ item }">
          {{ item.time | formatDate }} <br>
          at {{ item.time | formatTime }}
        </template>
        <template #[`item.value`]="{ item }">
          <EventValue
            :type="item.type"
            :values="item.values"
            :mutable="mutable"
            :allow-user-to-edit="allowUserToEdit"
            @edit="onEditEvent(item)"
            @delete="onDeleteEvent(item)"
          />
        </template>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>
import EventEntry from "@/components/patient/EventEntry";
import EventValue from "@/components/patient/EventValue";
import { DateTime } from "luxon";
import { get, cloneDeep } from "lodash";
import config from "../../config/instance";
import momentTz from "moment-timezone";
import constant from "../../config/constant";

export default {
  name: "EventTable",

  components: { EventValue, EventEntry },

  props: {
    generalDetails: { type: Object, default: () => {} },
    existingEvents: { type: Array, required: true, default: () => [] },
    mutable: { type: Boolean, default: true },
    allowUserToEdit: { type: Boolean, required: false, default: true },
    schema: { type: Array, default: () => [] },
  },

  data() {
    return {
      addEvent: true,
      isSelected: false,
      events: this.existingEvents,
      editIndex: -1,
      editEvent: null,
      showEventEntry: false,
      highlightRow: true,
      selectedRow: null,
      headers: [
        {
          text: "Event",
          value: "event",
          align: "left",
          sortable: false,
        },
        {
          text: "Time",
          value: "time",
          align: "right",
          sortable: false,
        },
        {
          text: "Value",
          value: "value",
          align: "right",
          sortable: false,
        },
      ],
      dialog: false,
      message: constant.eventTable.content,
      index: "",
      options: {
        width: 480,
        zIndex: 0,
      },
    };
  },

  computed: {
    timezone() {
      return get(this.$config, "date_time.events.text", "");
    },
  },

  watch: {
    existingEvents(value) {
      this.events = value;
    },
    events: {
      deep: true,
      handler(value) {
        this.$emit("update:existingEvents", value);
      },
    },
  },

  mounted() {
    this.headers[1].text = this.timezone;
  },

  methods: {
    dialogBox() {
      this.dialog = true;
      this.message = constant.eventTable.content;
    },
    agree() {
      this.events.splice(this.index, 1);
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
      // this.highlightRow = false;
    },
    rowClick: function (item, row) {
      this.selectedRow = row;
      if (!this.highlightRow) {
        row.select(true);
      }
      this.highlightRow = true;
    },

    toggleEdit() {
      this.showEventEntry = false;
      this.editIndex = -1;
      this.editEvent = null;
    },
    addItem() {
      this.toggleEdit();
      this.showEventEntry = true;
    },
    onCloseEventEntry() {
      if (this.selectedRow) {
        this.selectedRow.select(false);
      }
      this.toggleEdit();
      this.addEvent = true;
    },
    onSaveEventEntry(event) {
      this.addEvent = true;
      this.isSelected = false;
      if (event) {
        let utcDate = momentTz
          .tz(
            `${event.date}T${event.time}`,
            "YYYY-MM-DD HH:mm:ss",
            config.date_time.events.zone
          )
          .toISOString();
        Object.assign(event, { time: Date.parse(new Date(utcDate)) });
        if (this.editIndex > -1) {
          this.events.splice(this.editIndex, 1, event);
        } else {
          this.events.push(event);
        }
      }

      this.events.sort(function compare(a, b) {
        var dateA = new Date(a.time);
        var dateB = new Date(b.time);
        return dateA - dateB;
      });
      this.toggleEdit();
    },
    onDeleteEvent(event) {
      this.index = this.events.indexOf(event);
      this.highlightRow = true;
      this.dialogBox();
    },
    onEditEvent(event) {
      this.highlightRow = false;
      this.addEvent = false;
      this.toggleEdit();
      this.editIndex = this.events.indexOf(event);
      const zone = config.date_time.events.zone;
      const dateTime = DateTime.fromMillis(event.time,{zone});
      this.editEvent = {
        ...cloneDeep(event),
        date: dateTime.toISODate(),
        time: dateTime.toLocaleString(DateTime.TIME_24_SIMPLE),
        timestamp: event.time,
      };
      this.showEventEntry = true;
    },
  },
};
</script>
<style>
tr.v-data-table__selected {
  background: #ffc7b3 !important;
}
#agree {
  background-color: #0035c7 !important;
  color: white !important;
}
#cancel {
  background-color: #ef5151 !important;
  color: white !important;
}
#id{
  font-size: 10px;
   color: rgb(73, 13, 13) !important;
}
</style>
