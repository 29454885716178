<template>
  <div>
    <Card 
      title="Patient" 
      :loading="loading"
    >
      <PatientBasic
        ref="patientBasic"
        :new-patient="newPatient"
        :existing-patient-details.sync="generalDetailsData"
        :allow-user-to-edit="allowUserToEdit"
        :schema="patientBasicSchema"
      />
    </Card>
    <Card  
      title="Patient Information" 
      :loading="loading"
    >
      <PatientInformation
        v-if="schema.length > 0"
        ref="patientInformation"
        :existing-dose-details.sync="doseDetailsData"
        :existing-patient-details.sync="generalDetailsData"
        :patient-model="patientModel"
        :allow-user-to-edit="allowUserToEdit"
        :schema="patientInformationSchema"
        @validated="onValidated"
        @change="onChange($event, field)"
        @commonFunction="commonFunction"
      />
    </Card>
    <Card 
      title="Events" 
      :loading="loading"
    >
      <EventTable
        :general-details="generalDetailsData"
        :existing-events="events"
        :patient-model="patientModel"
        :allow-user-to-edit="allowUserToEdit"
        :schema="eventTableSchema"
      />
    </Card>
  </div>
</template>

<script>
import Card from "@/components/ui/Card";
import PatientBasic from "@/components/patient/PatientBasic";
import PatientInformation from "@/components/patient/PatientInformation";
import EventTable from "@/components/patient/EventTable";
import { orderBy } from "lodash";
import _ from "lodash";
export default {
  name: "PatientInformationTab",
  components: {
    Card,
    PatientBasic,
    PatientInformation,
    EventTable,
  },
  props: {
    newPatient: { type: Boolean, required: true, default: true },
    loading: { type: Boolean, required: true, default: false },
    patientModel: { type: String, default: "" },
    generalDetails: { type: Object, required: true, default: () => ({}) },
    doseDetails: { type: Object, required: true, default: () => ({}) },
    events: { type: Array, required: true, default: () => [] },
    allowUserToEdit: { type: Boolean, required: false, default: true },
    patientDetails: { type: Object, required: true, default: () => ({}) },
    schema: { type: Array, required: true, default: () => [] },
  },
  data() {
    return {
      patientInformationSchema: [],
      patientBasicSchema: [],
      eventTableSchema: [],
      eventsData:this.events,
      generalDetailsData:{},
      doseDetailsData:{}
    };
  },

  computed: {
    _() {
      return _;
    },
  },
  watch: {
    schema(value) {
      this.fields = orderBy(value, ["order"], ["asc"]);
    },
    generalDetails(){
      this.generalDetailsData =this.generalDetails
    },
    doseDetails(){
      this.doseDetailsData = this.doseDetails
    }
  },
  created() {
    if (
      _.find(this.schema, { title: "Patient information" }) &&
      _.find(this.schema, { title: "Patient" }).fields &&
      _.find(this.schema, { title: "Events" }).fields
    ) {
      this.patientInformationSchema = _.find(this.schema, {
        title: "Patient information",
      }).fields;
      this.patientBasicSchema = _.find(this.schema, {
        title: "Patient",
      }).fields;
      this.eventTableSchema = _.find(this.schema, { title: "Events" }).fields;
    }
  },
  mounted(){
    /* eslint-disable  */
    // console.log(this.generalDetailsData,'generalDetailsData');
     this.generalDetailsData =this.generalDetails
  },
  methods: {
    triggerValidate() {
      this.$refs.patientInformation.validate = false;
      this.$refs.patientBasic.validate = false;
      this.$nextTick(() => {
        this.$refs.patientInformation.validate = true;
        this.$refs.patientBasic.validate = true;
      });
    },
    onValidated() {
      const isValid =
        this.$refs.patientInformation.formValid &&
        this.$refs.patientBasic.formValid;
      this.$emit("valid", isValid);
    },
    commonFunction(v, key, type) {
      this.$emit("commonFunction", v, key, type);
    },
  },
};
</script>
