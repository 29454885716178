<template>
  <div data-app>
    <v-app-bar light>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <router-link :to="'/conditions/'">
        <v-img
          class="mx-2"
          src="@/assets/pumas-logo.png"
          max-height="100"
          max-width="100"
          contain
        />
      </router-link>

      <v-spacer />
      <v-btn
        id="home"
        icon
        name="home"
        @click="routeTo('Condition')"
      >
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-menu
        left
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            id="dots"
            icon
            v-bind="attrs"
            name="dots"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <!-- <v-list-item>
            <v-list-item-title @click="routeTo('Profile')" class="cp"
              >Profile</v-list-item-title
            >
          </v-list-item> -->
          <v-list-item>
            <v-list-item-title
              class="cp"
              @click="logout()"
            >
              Logout
            </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-title>User Profile</v-list-item-title>
          </v-list-item> -->
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-avatar
              color="#EF515B"
              size="56"
            >
              {{
                userName | firstLetter
              }}
            </v-avatar>
          </v-list-item-avatar>
          <v-btn
            class="cancelbtn"
            color="#ef515b"
            icon
            @click.stop="drawer = !drawer"
          >
            <span class="material-icons"> arrow_back </span>
          </v-btn>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="subtitlestyle">
              {{ userName }}
            </v-list-item-title>
            <!-- <v-list-item-subtitle class="subtitlestyle">
              {{
                email
              }}
            </v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="theme--text text--accent-4"
        >
          <v-list-item @click="routeTo('Condition')">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item
            v-if="userRole === 'admin'"
            @click="routeTo('Manage')"
          >
            <v-list-item-title>Manage</v-list-item-title>
          </v-list-item> -->
          <!-- <v-list-item>
            <v-list-item-title>Notifications</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
      <!-- <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logout()">
            Logout
          </v-btn>
        </div>
      </template> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../../config/instance";
import { Auth } from "aws-amplify";
import Error_Message from "@/utils/notification";

export default {
  name: "AppHeader",
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    firstLetter: function(value) {
      return value.charAt(0).toUpperCase() ;
    },
  },
  data() {
    return {
      drawer: false,
      group: null,
      userName: '',
      options: [{ text: "Profile", method: "profile" }],
    };
  },
  computed: {
    ...mapGetters(["userRole"]),
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  mounted(){
    this.userName = this.$store.getters.userInfo.username || '';
  },
  methods: {
    logout() {
      let script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      window.FreshworksWidget("identify", "ticketForm", {
        name: "",
        email: "",
      });
      window.FreshworksWidget("close");
      let myScript = document.createElement("script");
      myScript.setAttribute("type", "text/javascript");
      myScript.setAttribute("src", `${config.Freshwork.Widget_Url}`);
      document.head.appendChild(myScript);
      document.head.appendChild(script);

      try {
      Auth.signOut()
        .then(() => {
          this.$router.push({ name: "Login" });
      })
      }
      catch(e){
        this.$store.commit(
          "snackbar/setSnack",
          Error_Message.display_message(e)
        );
      }
      
    },
    routeTo(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style type="text/css">
.deep-purple--text {
  color: #ef515b !important;
  caret-color: #ef515b !important;
}
.cp {
  cursor: pointer !important;
}
.subtitlestyle {
  text-transform: none;
}
.v-btn.cancelbtn {
  margin-left: 138px !important;
  font-size: 13px !important;
}
.v-btn--icon.v-size--default {
  height: 30px !important;
  width: 30px !important;
}
</style>
