<template>
  <v-menu
    ref="menu1"
    v-model="menu1"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :id="id"
        v-model="selectedDate"
        v-mask="compMask"
        :label="label"
        :error="isNotValid"
        :rules="dateRules"
        :disabled="!allowUserToEdit"
        placeholder="YYYY-MM-DD"
        persistent-hint
        v-bind="attrs"
        prepend-icon="mdi-calendar"
        autocomplete="off"
        v-on="on"
        @keydown.tab="onTab()"
      />
    </template>
    <v-date-picker
      v-model="selectedDate"
      color="#EF515B"
      no-title
      :max="maxDate"
      :min="minDate"
      :disabled="!allowUserToEdit"
      @change="setDate($event)"
      @input="
        setDate($event);
        menu1 = false;
      "
    />
  </v-menu>
</template>

<script>
import validateInputMixin from "@/mixins/validateInputMixin";
import moment from "moment";
import { mask } from "vue-the-mask";
import config from "../../config/instance";

export default {
  name: "Datepicker",
  directives: {
    mask,
  },

  mixins: [validateInputMixin],

  props: {
    allowUserToEdit: { type: Boolean, required: false, default: true },
    // eslint-disable-next-line vue/require-default-prop
    maxDate: { type: String },
    minDate: { type: String, default: "1950-01-01" },
    options: {
      type: Object,
      default: function () {
        return {
          format: config.date_time.patient_information.format,
          clearable: false,
          readonly: false,
          icon: "mdi-calendar",
          errorMessage: "Data Inválida",
        };
      },
    },
  },
  data() {
    return {
      menu1: false,
      selectedDate: null,
    };
  },
  computed: {
    compMask: {
      get: function () {
        let format = this.options.format;
        format = this.replaceAll(format.toLowerCase(), "d", "#");
        format = this.replaceAll(format.toLowerCase(), "m", "#");
        format = this.replaceAll(format.toLowerCase(), "y", "#");
        return format;
      },
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.selectedDate = value;
      },
    },
    selectedDate(value) {
      let dateValid = moment(
        this.selectedDate,
        config.date_time.patient_information.format,
        true
      );
      if (dateValid._isValid) {
        this.$emit("input", value);
        this.$emit("change", value);
      }
    },
  },
  methods: {
    onTab() {
      this.menu1 = false;
    },
    replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, "g"), replace);
    },
    setDate($event) {
      this.selectedDate = $event;
      this.$emit("input", this.selectedDate);
      this.$emit("change", this.selectedDate);
    },
  },
};
</script>
